export default {
    cognitoBaseURL: "https://auth.rev.eatery-portal.com",
    region: "",
    pool_id: "ap-northeast-1_5BHYzSUEM",
    provider: {
        external: {
            client_id: "6pgmkieepj3ht9eghb98ehn4qq",
            client_secret: "1lj3hc0mnuojf4qrssmshfnepl6j9nlr7op3t5q34pk6a7k9lck8",
        },
        app: {
            client_id: "217qfpa5gdkv4ootimgb3ger66",
            client_secret: "NULL",
        },
    }
}